import * as React from "react";
import Layout from "@components/Layout";
import SEO from "@components/seo";
import Row from "@components/Container/Row";
import Column from "@components/Container/Column";
import ArticleCard from "@components/Container/ArticleCard";
import { graphql, PageProps } from "gatsby";
import { Markdowns, PageContext } from "models/pageModel";

interface BlogsPageContext {
    markdowns: Markdowns;
}

export type BlogsPageProps = PageContext<BlogsPageContext> & PageProps;

const Blogs = (props: BlogsPageProps) => {
    const { path, data } = props;
    const { markdowns } = data;
    return (
        <Layout>
            <SEO
                title="Ng Chen Hon"
                description="Ng Chen Hon's blog & website. I write codes and drink coffee."
                doNotAddNameSuffix={true}
                keywords={[
                    "Ng Chen Hon",
                    "Personal Blogs",
                    "Personal Website",
                    "Ng Chen Hon's blogs & website",
                    "Mutatedbread's blogs & website",
                ]}
                pagePath={path}
            />
            <Row>
                <Column>
                    {markdowns.edges.map((edge, index) => {
                        const {
                            image,
                            title,
                            summary,
                            slug,
                            date,
                        } = edge.node.frontmatter;
                        return (
                            <ArticleCard
                                image={image?.childImageSharp.fixed}
                                title={title}
                                subTitle={summary}
                                to={slug}
                                key={`article_card_${index}`}
                                titleLocation="top"
                                date={date}
                            />
                        );
                    })}
                </Column>
            </Row>
        </Layout>
    );
};

export default Blogs;

export const query = graphql`
    query {
        markdowns: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { type: { eq: "blog" } } }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        summary
                        slug
                        title
                        date
                        image {
                            childImageSharp {
                                fixed(width: 720, height: 288) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
